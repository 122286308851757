import { FC, useState, useEffect } from "react";

import { UploadStatusStyle as Styled } from "./uploadStatus.style";
import { Is3Event } from "screens/Inbox/types/inbox.types";
import { UploadingView } from "./UploadingView/UploadingView";
import { useUploadStatusState } from "./uploadStatus.state";

interface IUploadStatus {
    // isUploadingModalOpen: boolean;
    // onUploadingModalToggle: () => void;
    // onDeleteEventErrorHandler?: (jobid: string, key: string) => void;
    margin?: string;
    onFetchDataHandler: () => void;
    fieldFilter: 'receipt' | 'invoice';
    s3EventStatus: Is3Event[];
}


export const UploadStatus: FC<IUploadStatus> = (props) => {
	const { s3EventStatus, onFetchDataHandler, margin, fieldFilter } = props;
    // const receiptStatus = s3EventStatus.filter((eStatus) => eStatus.field === fieldFilter);
    const [totalUploads, setTotalUpload] = useState(s3EventStatus.length);
    const [totalSuccess, setTotalSuccess] = useState(0);
    const [totalError, setTotalError] = useState(0);
    const [totalPending, setTotalPending] = useState(s3EventStatus?.length);
    const [isNowClear, setNowClear] = useState<null | number>(null);
    const [isUplaodComplete, setUploadComplete] = useState<boolean>(false);

    const {isUploadingModalOpen, onUploadingModalToggle, onDeleteEventErrorHandler} = useUploadStatusState();


	useEffect(() => {
        // const receiptStatus = s3EventStatus.filter((eStatus) => eStatus.field === fieldFilter);
        setTotalUpload(s3EventStatus?.length);
        const successStatus: any[] = [];
        const processingStatus: any[] = [];
        s3EventStatus.forEach((stat) => stat.progress == 100 && stat?.output?.status !== 'processing' ? successStatus.push(true) : stat?.output?.status === 'processing' ? processingStatus.push(true): null);
        setTotalSuccess(successStatus.length);
        const errorStatus: any[] = [];
        s3EventStatus.forEach((stat) => stat.status=='error' ? errorStatus.push(true) : null);
        // s3EventStatus.map((stat) => stat.status=='error');
        setTotalError(errorStatus.length);
        // console.log(successStatus);
        setTotalPending(totalUploads - (successStatus.length + errorStatus.length ));
        setNowClear(totalUploads - (successStatus.length + errorStatus.length + processingStatus.length ));
        setUploadComplete(isNowClear && isNowClear > 0 ? true : false); //when new receipt came its make its value true, so when isNowClear value again 0, it will call fetch data function.
	}, [s3EventStatus, totalError]);

    useEffect(() => {
        console.log('effect runs');
        if(isNowClear === 0 && isUplaodComplete) {
            setUploadComplete(false);
            setTimeout(() => {
                onFetchDataHandler();
            }, 4000);
        }
    }, [isNowClear, s3EventStatus]);

	return (
        <>
        <Styled.Wrapper onClick={onUploadingModalToggle} margin={margin} title={`Total(${totalUploads}) | Success(${totalSuccess}) | Pending(${totalPending}) | Rejected(${totalError})`}>
            <Styled.Title>Uploading... <Styled.Stats>Total: {totalUploads} ( <Styled.Stat color={"green"}>{totalSuccess}</Styled.Stat> | <Styled.Stat color={"mango"}>{totalPending}</Styled.Stat> | <Styled.Stat color={"red"}>{totalError}</Styled.Stat>)</Styled.Stats></Styled.Title>
            <Styled.Bar>
                <Styled.Progress width={(100 * totalSuccess) / totalUploads}></Styled.Progress>
                {/* <Styled.Progress width={((100*totalPending)/100)-100}></Styled.Progress> */}
            </Styled.Bar>
        </Styled.Wrapper>

		<UploadingView modalShow={isUploadingModalOpen} modalToggle={onUploadingModalToggle} s3EventStatus={s3EventStatus} onDeleteEventErrorHandler={onDeleteEventErrorHandler} overallStatus={[totalUploads, totalSuccess, totalPending, totalError]} title={fieldFilter === 'receipt' ? 'Status | Uploading Receipts...' : 'Status | Uploading Invoices...'} />
        </>
    )
};
