import React from 'react';
import {io, Socket} from 'socket.io-client';
import { CONFIG } from 'constants/config';
import { apiServices } from 'services/api-service';

import { persisterStore, store } from 'services/redux/store';
import { setIsFetchingData, setReceipts, setS3Presigned, setS3EventStatus, setS3PendingStatus } from '../screens/Inbox/reducer/inbox.reducer';
import { setSocket } from 'services/redux/socketReducer';

import { useDispatch, useSelector } from 'react-redux';
import { IState } from 'services/redux/reducer';
import { useEffect, useRef } from 'react';
import { Is3Event } from 'screens/Inbox/types/inbox.types';

export const useSocket = (shouldConnect?: boolean) => {
        const dispatch = useDispatch();
        // const socketRef = useRef<any>(null);
        let mySocket: any;

    const {
        user: { token },
        inbox: {s3Uploadpresigned, s3EventStatus},
        socket: {socketSingleTon}
        } = useSelector((state: IState) => state);
    // console.log(s3Uploadpresigned, token, socketSingleTon, typeof socketSingleTon);

    // const loginID = await apiServices.fetchData(`${CONFIG.apiUrl}profile/get`);
    // console.log(loginID.data?.user?.id);

    const socketOptions = {
        extraHeaders: {
            Authorization: "Bearer " + token + ""
        }
    }

    useEffect(() => {
        // console.log('react entry in socket effect');
        if (shouldConnect && !mySocket && !socketSingleTon) {
            // console.log('react entry in socket effect if');
            // socketRef.current = io(CONFIG.apiUrl || '', socketOptions);
            // dispatch(setSocket(socketRef));
            mySocket = io(CONFIG.apiUrl || '', socketOptions);
            dispatch(setSocket(mySocket));

            mySocket.on('connect', () => {
                // console.log('Ack Connected to server');
            });

            mySocket.on("s3_event", (data: Is3Event) => {
                // console.log('s3_event listening', data);
                dispatch(setS3EventStatus(data));
                // console.log('s3_event store', s3EventStatus);
            });

            mySocket.on('exception', function (response: any) {
                console.log('exception error', response);
            });

            mySocket.on('disconnect', () => {
                console.log('Socket Disconnected from server');
            });

            mySocket.on("connect_error", (error: any) => {
                if (mySocket.active) {
                    console.log('socket reconnect');
                    // temporary failure, the socket will automatically try to reconnect
                } else {
                    // the connection was denied by the server
                    // in that case, `socket.connect()` must be manually called in order to reconnect
                    console.log(error.message);
                }
            });
        }
        return () => {
            if (mySocket) {
                mySocket.disconnect();
                mySocket = null;
            }
        };
        }, [shouldConnect, token]);
    
    const s3Ack = async (jobID: string) => {
        const loginID = await apiServices.fetchData(`${CONFIG.apiUrl}profile/get`);
        // console.log(loginID.data?.user?.id);
        if (mySocket && mySocket.connected) {
                // Emit the 's3_ack' event
                mySocket.emit('s3_ack', { loginId: loginID.data?.user?.id, jobId: jobID }, (response: any) => {
                        console.log('s3_ack -->', response);
                    }
                );
        } else {
                console.error('Socket is not connected, unable to emit event');
        }
    }
    
    //  on call disconnect
    //  const s3Disconnect = async (disconnect = false) => {
    //     console.log(disconnect)
    //     if (disconnect) {
    //         socket.disconnect();
    //    }
    // }

    return { s3Ack };
    };


// =====> old logic for socket
// export const useClientSocketIO = () => {
//     const dispatch = useDispatch();
// const {
//     user: { token },
//     inbox: {s3Uploadpresigned, s3EventStatus}
//     } = useSelector((state: IState) => state);
// console.log(s3Uploadpresigned, token, CONFIG.apiUrl);

// const socketOptions = {
//     extraHeaders: {
//         Authorization: "Bearer " + token + ""
//     }
// }

// const socket = io(CONFIG.apiUrl || '', socketOptions);
// socket.on('connect', () => {
//     console.log('Ack Connected to server');
// });

// const s3Ack = async (jobID: string) => {
//     const loginID = await apiServices.fetchData(`${CONFIG.apiUrl}profile/get`);
//     console.log(loginID.data?.user?.id);
//             // Emit the 's3_ack' event
//             socket.emit('s3_ack', { loginId: loginID.data?.user?.id, jobId: jobID },
//                 (response: any) => {
//                     console.log('s3_ack -->', response);
//                 }
//             );
    
// }
// const s3Listen = async () => {
//     socket.on("s3_event", (data) => {
//         console.log('s3_event listening', data);
//         dispatch(setS3EventStatus(data));
//         console.log('s3_event store', s3EventStatus);
//     });
// }

// const s3Disconnect = async (disconnect = false) => {
//     console.log(disconnect)
//     if (disconnect) {
//         socket.disconnect();
//     }
//     socket.on('disconnect', () => {
//         console.log('Socket Disconnected from server');
//     });
// }

// socket.on('exception', function (response) {
//     console.log('exception error', response);
// });

// socket.on("connect_error", (error) => {
//     if (socket.active) {
//         console.log('socket reconnect');
//         // temporary failure, the socket will automatically try to reconnect
//     } else {
//         // the connection was denied by the server
//         // in that case, `socket.connect()` must be manually called in order to reconnect
//         console.log(error.message);
//     }
// });


//   return { s3Ack, s3Listen, s3Disconnect };
// };


