import { useState, useEffect } from "react";

import { LoaderComponent } from "components/Loader";

import { useSelector } from 'react-redux';
import { TableUploadViewStyles as Styled } from "./uploadingView.style";
import { Is3Event } from "screens/Inbox/types/inbox.types";

import { ReUseActionPlaceholder } from "ReUseComponents/reUseActionPlaceHolder/ReUseActionPlaceHolder";
import { StatusLabel } from "components/StatusLabel/StatusLabel";
import { Icon } from "components/Icons";
import { UploadViewModal } from "./UploadViewModal";
import { ReUseActionButton } from "ReUseComponents/reUseActionButton/ReUseActionButton";
import { IState } from "services/redux/reducer";

interface IUploadViewProps {
	modalShow: boolean;
	modalToggle: () => void;
	onDeleteEventErrorHandler?: (jobid: string, key: string) => void;
	s3EventStatus: Is3Event[];
	overallStatus: number[];
	title: string;	
}

export const UploadingView: React.FC<IUploadViewProps> = (props) => {
	const { modalShow, modalToggle, s3EventStatus, overallStatus, onDeleteEventErrorHandler, title } = props;

	// const socketEmit = (jobid: string) => {
	// 	if (socketSingleTon) {
	// 		// Emit the 's3_ack' event
	// 		console.log('time2',new Date().getTime());
	// 		socketSingleTon.emit('s3_ack', { loginId: id, jobId: jobid }, (response: any) => {
	// 			console.log('s3_ack -->', response);
	// 		});
	// 	} else {
	// 		console.error('Socket is not connected, unable to emit event');
	// 	}
	// }

	return (
		<>
			{false ? (
				<Styled.LoaderWrapper>
					<LoaderComponent theme="preview" />
				</Styled.LoaderWrapper>
			) : (
				<UploadViewModal isModalWindowOpen={modalShow} onCloseModalWindowHandler={modalToggle}>
					{/* <Styled.Wrapper> */}
					<ReUseActionPlaceholder>
						<Styled.Title>{title}</Styled.Title>
						<Styled.Stats>
							Total: {overallStatus[0]} ( <Styled.Stat color={"green"}>Success: {overallStatus[1]}</Styled.Stat> | <Styled.Stat color={"mango"}>Pending: {overallStatus[2]}</Styled.Stat> | <Styled.Stat color={"red"}>Error: {overallStatus[3]}</Styled.Stat> )
						</Styled.Stats>
						<Styled.Icon onClick={modalToggle}>
							<Icon type="closeBig" />
						</Styled.Icon>
					</ReUseActionPlaceholder>
					<Styled.TableWrapper>
						<>
							<Styled.Head>
								{/* <Styled.Text>Icon</Styled.Text> */}
								<Styled.Text>File Name</Styled.Text>
								{/* <Styled.Text>Status Message</Styled.Text> */}
								<Styled.Text>Status</Styled.Text>
								<Styled.Text>Progress / Action</Styled.Text>
							</Styled.Head>

							{s3EventStatus?.map((s3, i) => {
								// if (s3?.message == "Provisioned rate exceeded" && socketSingleTon?.connected) { 
								// 	// socketEmit(s3?.job_id);
								// 	// console.log('time2',new Date().getTime());
								// 	socketSingleTon.emit('s3_ack', { loginId: id, jobId: s3?.job_id }, (response: any) => {
								// 	console.log('s3_ack again -->', response);
								// });
								// } else {
								// 	console.log('retry fail');
								// }
								return (
									<Styled.Item key={i}>
										<Styled.Selector title={s3?.filename}>
											<Styled.ValueWrapper>{s3?.filename || "-----"}</Styled.ValueWrapper>
										</Styled.Selector>
										<Styled.Status title={s3?.output?.status == 'processing' ? 'complex data found - might be it take long time to process.' : s3?.message}>
											<StatusLabel status={s3?.status == "error" ? "error" : s3?.progress == 100 && s3?.output?.status == 'processing'  ? "processing" : s3?.progress == 0 ? "pending" : s3?.progress == 100 ? 'success' : ("fetching" as Statuses)} />
										</Styled.Status>
										<Styled.Status>
											{/* {s3?.status == "success" && i == 1 ? ( */}
											{s3?.status == "error" ? (
												<>
													<ReUseActionButton
														displayText="Remove"
														buttonType="actionButton"
														widthType="primary"
														themedButton="secondary"
														customColor="darkRed"
														customHeight="30px"
														iconHeight="16px"
														// customwidth="60%"
														customwidth="100%"
														displayIconType="deleteAccount"
														customClick={() => onDeleteEventErrorHandler && onDeleteEventErrorHandler(s3?.job_id, s3?.key || "")} /* customColor={`${theme.colors.red}`} fontSize="22px" */
													/>
													{/* |
													<Styled.IconWrapper onClick={() => onDeleteEventErrorHandler && onDeleteEventErrorHandler(s3?.job_id, s3?.key || "")}>
														<Icon type="deleteAccount" />
													</Styled.IconWrapper> */}
												</>
											) : (
												<Styled.Bar width={s3?.output?.status === 'processing' ? 85 : s3?.progress}>
													<Styled.Progress width={s3?.output?.status === 'processing' ? 85 : s3?.progress}></Styled.Progress>
													{/* <Styled.Progress width={100 - ((100 * totalPending) / totalUploads)}></Styled.Progress> */}
												</Styled.Bar>
											)}
										</Styled.Status>
									</Styled.Item>
								);
							})}
						</>
					</Styled.TableWrapper>
					{/* </Styled.Wrapper> */}
				</UploadViewModal>
			)}
		</>
	);
};

{
	/* <Styled.Selector>
        <Styled.ValueWrapper>{IconName && <Icon type={IconName || 'addPlus'} />}</Styled.ValueWrapper>
    </Styled.Selector> */
}

{
	/* <Styled.Selector title={s3?.message}>
		<Styled.ValueWrapper>{s3?.message || "-----"}</Styled.ValueWrapper>
	</Styled.Selector> */
}
