import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Axios from 'axios';
import { CONFIG } from 'constants/config';
import { apiServices } from 'services/api-service';

import { IState } from 'services/redux/reducer';
import { useOutsideClick } from 'hooks/useOutsideClick';
import { useToggle } from 'hooks/useToggle';
import { statusDeleteApi } from './uploadStatus.api';
import { deleteS3EventStatus } from 'screens/Inbox/reducer';

export const useUploadStatusState = () => {
    const dispatch = useDispatch();
    const {
        // filesUpload: { previewFiles, filesArray, activeIndex },
        user: { token, user },
        // inbox: { s3EventStatus },
        socket: { socketSingleTon }
    } = useSelector((state: IState) => state);

    const [isUploadingModalOpen, onUploadingModalToggle] = useToggle();

    const onDeleteEventErrorHandler = async (jobid: string, key: string) => {
        const { data } = await statusDeleteApi(key);
        if (data?.success == true) {
            dispatch(deleteS3EventStatus(jobid));
        }
    }

    return {
        isUploadingModalOpen,
        onUploadingModalToggle,
        onDeleteEventErrorHandler,
    };
};