import React, { FC } from 'react';

import { AvatarBox } from './AvatarBox';
// import { LinksList } from './LinksList';
import { SidebarStyles as Styled } from './Sidebar.style';
import { Icon } from 'components/Icons/Icons';
import { LinkItem } from './LinkItem';
import { useSideBarState } from './SideBar.state';
import { Button } from 'components/Button';
import { ROUTES } from 'constants/routes';
import styled from 'styled-components';
import { DeleteModalWindow } from '../DeleteModalWindow';
import { ReUseActionButton } from 'ReUseComponents/reUseActionButton/ReUseActionButton';

interface ISideBar {
  userRole: TRoles;
  userFullName: string;
  avatatSrc: string;
  onUploadProfilePhotoHandler: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => Promise<void>;
  isUploadingPhoto: boolean;
  isHover: boolean;
  onMouseEnterHandler: () => void;
  onMouseLeaveHandler: () => void;
  isActiveAccount: boolean;
}

export const Sidebar: FC<ISideBar> = (props) => {
  const {
    userFullName,
    userRole,
    avatatSrc,
    isUploadingPhoto,
    isHover,
    isActiveAccount,
    onMouseEnterHandler,
    onMouseLeaveHandler,
    onUploadProfilePhotoHandler,
  } = props;
  const {
    settingsLink,
    logoutHandler,
    onLogOut,
    onDeleteModalWindowToggle,
    isDeleteModalWindowOpen,
    onDelete
  } = useSideBarState();
  return (
    <Styled.MainWrapper>
      <AvatarBox
        id="avatar"
        name="avatar"
        isActiveAccount={isActiveAccount}
        isUploadingPhoto={isUploadingPhoto}
        userRole={userRole}
        avatarSrc={avatatSrc}
        onChangeAvatarHandler={onUploadProfilePhotoHandler}
        userFullName={userFullName}
        isHover={isHover}
        onMouseEnterHandler={onMouseEnterHandler}
        onMouseLeaveHandler={onMouseLeaveHandler}
      />
      {/* <LinksList isActiveAccount={isActiveAccount}/> */}

      <Styled.listWrapper data-testid="links">
      {settingsLink.map((link) => (
        <LinkItem
          // icon = {<Icon type="accountIcon"/>}
          icon={link.icon}
          key={link.title}
          path={link.route}
          title={link.title}
          onClick={link.onClick}
          exact={link.route === '/settings'}
          isDisabled={
            !isActiveAccount &&
            link.route !== ROUTES.settings &&
            !isActiveAccount &&
            link.route !== ROUTES.login
          }
        />
      ))}
        </Styled.listWrapper>


      <Styled.ButtonWrapper>
        {/* <Button
          onClick={onLogOut}
          themedButton="primary"
          width="secondary"
        >
         Logout
        </Button> */}
                <ReUseActionButton displayText="Logout" buttonType="actionButton" customClick={onLogOut}  widthType="primary" themedButton='primary'  displayIconType="logoutIcon" customwidth='100%' margin='30px 0 0 0' />
                </Styled.ButtonWrapper>

      <DeleteModalWindow
        isLoading={false}
        onCloseDeleteModalWindowHandler={onDeleteModalWindowToggle}

        onDeleteButtonClickHandler={onDelete}
        isDeleteModalWindowOpen={isDeleteModalWindowOpen}
        deleteItemName={''}
        account={'yes'}
        categoryName=""
      ></DeleteModalWindow>
    </Styled.MainWrapper>
  );
};
