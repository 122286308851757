import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { IState } from 'services/redux/reducer';

import { selectReport } from 'screens/ExpenseReport/reducer/expenseReport.reducer';

import { ROUTES } from 'constants/routes';

interface IuseTableInboxAdminItemState {
  reportId: string;
  reportIndex: number;
}

export const useTableExpenseState = (
  props: IuseTableInboxAdminItemState
) => {
  const { reportIndex } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // console.log("index no.  and   Id :-- ", reportIndex ,reportId);
  
  const user = useSelector((state: IState) => state.user);
  // console.log("user.userInfo:-------",user.userInfo);
  const onExpenseDetailsClickHandler = (
    event: React.MouseEvent<HTMLDivElement>
  ) => {
    console.log("Expense details clicked");
    dispatch(selectReport(reportIndex));
    // navigate(ROUTES.receiptDetails);
    // navigate(ROUTES.expenseDetailsPageRoute);
    navigate(ROUTES.expenseDetailsPageRoute)
    console.log(ROUTES.expenseDetailsPageRoute);
  };
  // const onExpenseDetailsClickHandler = (
  //   event: React.MouseEvent<HTMLDivElement>
  // ) => {
  //   event.preventDefault();
  //   event.stopPropagation();
  //   navigate(ROUTES.expenseDetails);
  // };
  return { ...user.userInfo, onExpenseDetailsClickHandler };
};

