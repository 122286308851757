import React, { FC, useState, useEffect } from "react";
import { ButtonsBoxStyles as Styled } from "./ButtonsBox.style";
import { ReUseActionButton } from "ReUseComponents/reUseActionButton/ReUseActionButton";
import { Button } from "components/Button";

interface IButtonBoxProps {
	secondButtonText?: string;
	saveReceiptHandler?: () => Promise<void>;
	onCancelButtonClickHandler?: () => void;
	onApproveButtonClickHandler?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
	onRejectButtonClickHandler?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
	isLoading?: boolean;
	buttonValue?: string;
}

export const ButtonsBox: FC<IButtonBoxProps> = ({ saveReceiptHandler, onCancelButtonClickHandler, onApproveButtonClickHandler, onRejectButtonClickHandler, isLoading, buttonValue }) => {
	// console.log('!!!!!!!!!!!!!!!!! - RDContent child-buttonold');
	const [selectedButton, setSelectedButton] = useState<string | null>(null);

	const handleButtonClick = (buttonType: string, handler?: () => void | Promise<void>, event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		setSelectedButton(buttonType);
		if (handler) {
			handler();
		}
	};

	const handleApproveButtonClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		if (onApproveButtonClickHandler) {
			const customEvent = {
				...event,
				currentTarget: { ...event.currentTarget, value: "approved" },
			};
			onApproveButtonClickHandler(customEvent as React.MouseEvent<HTMLButtonElement, MouseEvent>);
		}
		setSelectedButton("approved");
	};

	const handleRejectButtonClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		if (onRejectButtonClickHandler) {
			const customEvent = {
				...event,
				currentTarget: { ...event.currentTarget, value: "rejected" },
			};
			onRejectButtonClickHandler(customEvent as React.MouseEvent<HTMLButtonElement, MouseEvent>);
		}
		setSelectedButton("rejected");
	};

	useEffect(() => {
		if (selectedButton) {
			const timer = setTimeout(() => {
				setSelectedButton(null);
			}, 2000); // delay in ms, adjust as needed
			return () => clearTimeout(timer); // cleanup timeout if component unmounts
		}
	}, [selectedButton]);

	return (
		// <Styled.ButtonsWrapper>
		<>
			<Button
				// onClick={handleRejectButtonClick}
				themedButton={"roundedWhite"}
				width="rounded"
				isLoading={selectedButton === 'approved' && isLoading}
				isDisabled={selectedButton === 'approved' && isLoading}
			>
				{"Archive"}
			</Button>
			<Button onClick={handleRejectButtonClick} themedButton={selectedButton === "save" ? "roundedRed" : "roundedWhite"} width="rounded" isLoading={selectedButton === 'approved' && isLoading} isDisabled={selectedButton === 'approved' && isLoading}>
				{"Reject"}
			</Button>
			{/* <Button onClick={handleApproveButtonClick} themedButton={selectedButton === "accepted" ? "roundedRed" : "roundedWhite"} width="rounded" isLoading={selectedButton === 'accepted' && isLoading} isDisabled={selectedButton === 'accepted' && isLoading}>
				{"Approve & Save"}
			</Button> */}
			<ReUseActionButton displayText="Approve & Save" buttonType="actionButton" themedButton="roundedRed" onClickEvent={handleApproveButtonClick} margin="0 0 0 auto" />
			<Button onClick={saveReceiptHandler} themedButton={selectedButton === "save" ? "roundedRed" : "roundedWhite"} width="rounded" isLoading={selectedButton === 'save' && isLoading} isDisabled={selectedButton === 'save' && isLoading}>
				{"Save"}
			</Button>
			<Button onClick={(event) => handleButtonClick("cancel", onCancelButtonClickHandler, event)} themedButton={selectedButton === "cancel" ? "roundedRed" : "roundedWhite"} width="rounded" isDisabled={isLoading}>
				{"Cancel"}
			</Button>
		{/* </Styled.ButtonsWrapper> */}
		</>
	);
};
