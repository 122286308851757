import { FC ,useState } from "react";
import ReactModal from "react-modal";

import { Input } from "../Input";
import { ModalButtonsBox } from "../ModalButtonsBox";
import { ModalWindowHeader } from "../ModalWindowHeader";
import {
  MasterModalWindowStyles as Styled,
  MasterModalStyles,
} from "./MasterModalWindow.style";
import { CustomSelect } from "components/CustomSelect";

export const MasterModalSupplier: FC<IMasterModalSupplierProps> = (props) => {
  const {
    isModalWindowOpen,
    headerText,
    inputValue,
    isLoading,
    isDisableButton,
    onCloseModalWindowHandler,
    onChangeInputValueHandler,
    onSaveButtonCLickHandler,
    onEnterCreateItemClick,
    text,

    inbuiltCategory,
    // onChangesupplierDefaultCategoryHandler,
    dropdownOptions,
    supplierDefaultCategory,
    vat,
    onChangeSupplierVatHandler,
    onChangeSupplierCategoryHandler,
    dropDownCategory,
    category,
  } = props;

  const [selectedValue, setSelectedValue] =useState('');

const handleDropdownChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
  setSelectedValue(event.target.value);
  
  };
  
  // const handleChange = (
  //   event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
  // ) => {
  //   if (event.target instanceof HTMLSelectElement) {
  //     handleDropdownChange(event); // This will work without errors
  //   }
  // };
  return (
    <ReactModal
      isOpen={isModalWindowOpen}
      onRequestClose={onCloseModalWindowHandler}
      ariaHideApp={false}
      style={MasterModalStyles}
    >
      <Styled.ModalMasterContentWrapper>
      <Styled.HeaderBox>
        <Styled.Title>{headerText}</Styled.Title>
      </Styled.HeaderBox>
      <Styled.MainContentWrapper>
        <Input
          onChangeValue={onChangeInputValueHandler}
          value={inputValue}
          text={text}
          onKeyDown={onEnterCreateItemClick}
          isRemoveBorder
          />
        
            <CustomSelect
              options={dropdownOptions}
          value={vat}
          onChangeValueHandler={onChangeSupplierVatHandler}
          label="Select VAT(%):"
            />
                <CustomSelect
          options={dropDownCategory}
          value={category}
              onChangeValueHandler = {onChangeSupplierCategoryHandler}
          label="Select Category:"
        />
         
      </Styled.MainContentWrapper> 
      <ModalButtonsBox
        isLoading={isLoading}
        onCancelClickHandler={onCloseModalWindowHandler}
        onSaveButtonCLickHandler={onSaveButtonCLickHandler}
        isSaveButton
        isDisableButton={isDisableButton}
      />
      </Styled.ModalMasterContentWrapper>
    </ReactModal>
  );
};
