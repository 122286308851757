import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { IState } from 'services/redux/reducer';

import { selectReceipt } from '../Inbox/reducer/inbox.reducer';
import { getReceiptImage } from './receiptDetails.api';

import { ActionMeta, SingleValue } from 'react-select';
import { format } from 'date-fns';
import {
  getFormatedCurrencies,
  getReceiptDetailsCurrentSelectItem,
} from 'services/utils';
import {
  getAllMasterItems,
  updateReceiptItem,
  getImageUrlFromAws,
} from 'screens/ReceiptDetails/receiptDetails.api';
import {
  setIsFetchingData,
  updateReceipt,
} from 'screens/Inbox/reducer/inbox.reducer';
import { setItemsForSelect } from 'screens/ReceiptDetails/reducer/receiptDetails.reducer';
import {
  getInputFields,
  photoDetailsContentInitialState,
  TInputFields,
  returnTypeFunction,
} from './receiptDetails.constant';
import { ROUTES } from 'constants/routes';
import { DATE_FORMATS } from 'constants/strings';
import { IImageView } from './types/receiptDetails.types';

export const useReceiptDetailsState = () => {
  console.log('useReceiptDetailsState  call here ');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [imageSrc, setImageSrc] = useState('');
  const [isPDF, setIsPDF] = useState(false);
  const [isImageLoading, setImageLoading] = useState(false);

  const {
    inbox: { selectedReceipt, receipts, selectedReceiptIndex },
    user: {
      token,
      user: { active_account },
      currencies,
      userInfo: { company },
    },
    receiptDetails: { categoriesForSelect, suppliersForSelect, suppliersAccountForSelect, typesForSelect },
  } = useSelector((state: IState) => state);
  console.warn('receipt view -------', imageSrc, selectedReceipt);

  const onGoBackHandler = () => navigate(-1);

  const onClickGetNextReceiptHandler = () => {
    if (Number(selectedReceiptIndex) + 1 >= receipts.length) return;
    dispatch(selectReceipt(Number(selectedReceiptIndex) + 1));
  };

  const onClickGetPrevReceiptHandler = () => {
    if (Number(selectedReceiptIndex) + 1 <= 1) return;
    dispatch(selectReceipt(Number(selectedReceiptIndex) - 1));
  };

  const onFetchReceiptImageHandler = useCallback(async () => {
    try {
      const payload = { keys: [selectedReceipt?.photos[0] || ''] };
      const { data } = await getImageUrlFromAws(payload);
      console.log('Fetched data:', data);

      // Assuming the API response has the data array
      // dispatch(selectedRecieptPhoto(data.url));
      setImageSrc(data.url);

    } catch (error) {
      console.error('Failed to fetch receipts:', error);
    }
  }, [selectedReceipt]);
  // ===================================================>

  const formatedCurrencies = getFormatedCurrencies(currencies);
  const currentCurrency = formatedCurrencies.find(
    (item) => item.id === selectedReceipt?.currency?.id || 'BTC'
  );
  const currentType = getReceiptDetailsCurrentSelectItem(
    typesForSelect,
    selectedReceipt?.payment_type?.id || ''
  );
  const currentCategory = getReceiptDetailsCurrentSelectItem(
    categoriesForSelect,
    selectedReceipt?.category?.id || ''
  );
  const currentSupplierAccount = getReceiptDetailsCurrentSelectItem(
    suppliersForSelect,
    selectedReceipt?.supplier_account?.id || ''
  );

  const initialState = {
    ...photoDetailsContentInitialState,
    currencyValue: "test",
    // currencyValue: currentCurrency,
    // paymentStatus: isPaymentStatus,
    paymentTypeValue: currentType,
    categoryValue: currentCategory,
    supplierAccountValue: currentSupplierAccount,
  };
  const [state, setState] = useState<IusePhotoDetailsContentState>(initialState);

  useEffect(() => {
    // console.log('===================', selectedReceipt);
    setState((prevState) => ({
      ...prevState,
      statusValue: selectedReceipt?.status || '',
      categoryValue: currentCategory || null,
      paymentTypeValue: currentType || null,
      dateValue: selectedReceipt?.receipt_date || null,
      supplierValue: selectedReceipt?.supplier || '',
      supplierAccountValue: currentSupplierAccount || null,
      currencyValue: currentCurrency,
      currencyValueId: selectedReceipt?.currency?.id || 'BTC',
      taxValue: selectedReceipt?.tax || null,
      totalValue: selectedReceipt?.total || null,
      receiptid: selectedReceipt?.custom_id || '',
      vatCodeValue: selectedReceipt?.vat_code || '',
      netValue: selectedReceipt?.net || null,
      formattedDate: selectedReceipt?.receipt_date
        ? format(new Date(selectedReceipt?.receipt_date), company.date_format)
        : '',
      paymentStatus: selectedReceipt?.payment_status || false,
      publishedStatus: selectedReceipt?.publish_status || false,
      descriptionValue: selectedReceipt?.description || '',
    }));
  }, [selectedReceipt?.id]);

  const [ButtonValue, setButtonValue] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const onCancelButtonClickHandler = () => navigate(-1);

  const onDatePickerClickHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    datePickerRef.current &&
      datePickerRef?.current.contains(e.target as Node) &&
      setIsOpen(!isOpen);
  };

  const onChangeStateFieldHandler = (
    optionName: keyof typeof initialState,
    value: string | boolean | number | null | Date | SingleValue<IOption> | any
  ) => {
    // console.log('##########',value);
    setState((prevState) => ({
      ...prevState,
      [optionName]: value,
    }))
  };

  const onGetAllMasterItemsHandler = async () => {
    try {
      const categoriesData = await getAllMasterItems(
        'category',
        active_account || ''
      );
      const supplierData = await getAllMasterItems(
        'supplier',
        active_account || ''
      );
      const supplierAccountData = await getAllMasterItems(
        'supplier-account',
        active_account || ''
      );
      const typesData = await getAllMasterItems(
        'payment-type',
        active_account || ''
      );
      console.warn('---qqqqqqqqqqqq', categoriesData);

      dispatch(
        setItemsForSelect({
          fieldName: 'categoriesForSelect',
          items: categoriesData.data,
        })
      );
      dispatch(
        setItemsForSelect({
          fieldName: 'suppliersForSelect',
          items: supplierData.data,
        })
      );
      dispatch(
        setItemsForSelect({
          fieldName: 'suppliersAccountForSelect',
          items: supplierAccountData.data,
        })
      );
      dispatch(
        setItemsForSelect({
          fieldName: 'typesForSelect',
          items: typesData.data,
        })
      );
    } catch (error) {
      console.log(error);
    }
  };

  const onChangeRadioButtonHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    setButtonValue(event.currentTarget.value);
  };

  const onForbiddenCharacterClick = (event: React.KeyboardEvent) => {
    if (event.key === '-' || event.key === 'e' || event.key === '+') {
      event.preventDefault();
    }
  };
  useEffect(() => { if (ButtonValue != '') saveReceiptHandler(); }, [ButtonValue]);

  const onChangeCategoryFieldHandler = (
    newValue: unknown,
    actionMeta: ActionMeta<unknown>
  ) => onChangeStateFieldHandler('categoryValue', newValue);

  const onChangeSupplierFieldHandler = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => { console.log("supplier value----@#$%#$%s", event.target.value); onChangeStateFieldHandler('supplierValue', event.target.value) };

  const onChangeSupplierAccountHandler = (
    newValue: unknown,
    actionMeta: ActionMeta<unknown>
  ) => onChangeStateFieldHandler('supplierAccountValue', newValue);

  const onChangeCurrencyFieldHandler = (
    newValue: any,
    actionMeta: ActionMeta<unknown>
  ) => {
    onChangeStateFieldHandler('currencyValue', newValue);
    onChangeStateFieldHandler('currencyValueId', newValue.id);
  };

  const onChangePaymentTypeFieldHandler = (
    newValue: unknown,
    actionMeta: ActionMeta<unknown>
  ) => onChangeStateFieldHandler('paymentTypeValue', newValue);

  const onChangeDescriptionFieldHandler = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => onChangeStateFieldHandler('descriptionValue', event.target.value);

  const onChangeTaxFieldHandler = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (event.target.value.length > 8) return;
    onChangeStateFieldHandler('taxValue', event.target.value);
  };

  const onChangeVatCodeFieldHandler = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (event.target.value.length > 15) return;
    onChangeStateFieldHandler('vatCodeValue', event.target.value);
  };

  const onChangeNetFieldHandler = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => onChangeStateFieldHandler('netValue', event.target.value);

  const onChangeTotalFieldHandler = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => onChangeStateFieldHandler('totalValue', event.target.value);

  const onChangeReceiptIdFieldHandler = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => onChangeStateFieldHandler('receiptid', event.target.value);

  const onChangeDate = (date: Date) => {
    setIsOpen(!isOpen);
    setState((prevState) => ({
      ...prevState,
      dateValue: date,
      formattedDate: format(date, company.date_format || DATE_FORMATS[0].value),
    }));
  };

  const onClickOutsideDatePickerHandler = (
    event: React.MouseEvent<HTMLDivElement>
  ) => {
    datePickerRef.current &&
      !datePickerRef?.current.contains(event.target as Node) &&
      setIsOpen(false);
  };

  console.log("state----", state);
  const saveReceiptHandler = async () => {
    try {
      console.log("state", state);
      const payload: IUpdateReceiptItemPayload = {
        active_account: active_account || '',
        id: selectedReceipt?.id || '',
        category: state.categoryValue?.id || selectedReceipt?.category,
        currency: state.currencyValueId || selectedReceipt?.currency.id,
        net: state.netValue || selectedReceipt?.net,
        payment_type: state.paymentTypeValue?.id || selectedReceipt?.payment_type,
        receipt_date: state.dateValue || selectedReceipt?.receipt_date,
        status: ButtonValue || selectedReceipt?.status,
        supplier: state.supplierValue || selectedReceipt?.supplier,
        supplier_account: state.supplierAccountValue?.id || selectedReceipt?.supplier_account,
        tax: state.taxValue || selectedReceipt?.tax,
        total: state.totalValue || selectedReceipt?.total,
        vat_code: state.vatCodeValue || selectedReceipt?.vat_code,
        // payment_status: state.paymentStatus  || selectedReceipt?.payment_status,
        payment_status: state.paymentStatus === true ? true : state.paymentStatus === false ? false : selectedReceipt?.payment_status,
        publish_status: state.publishedStatus,
        description: state.descriptionValue || selectedReceipt?.description,        // custom_id: state.receiptid || selectedReceipt?.custom_id,
      };

      setIsLoading(true);
      console.log("save button", state.paymentStatus, payload.payment_status);

      const { data } = await updateReceiptItem(payload);
      // setIsLoading(false);
      dispatch(updateReceipt(data));
      dispatch(setIsFetchingData(true));

      navigate(ROUTES.purchaseInvoices);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      dispatch(setIsFetchingData(false));
    }
  };  // console.log("ButtonValue" , ButtonValue);  // console.log("state", state);  // console.log("SELECTED" , selectedReceipt);

  const inputFields = [
    {
      type: 'text',
      label: 'Status',
      value: state.statusValue ? state.statusValue : '',
    },
    {
      type: 'text',
      label: 'Receipt ID',
      value: state.receiptid ? state.receiptid.toUpperCase() : '',
      status: state.statusValue ? state.statusValue : '',
    },
    {
      type: 'input',
      label: 'Supplier',
      isDisabled: !suppliersForSelect?.length,
      value: state.supplierValue,
      options: suppliersForSelect,
      onChange: onChangeSupplierFieldHandler,
    },
    {
      type: 'select',
      label: 'Supplier Account',
      isDisabled: !suppliersAccountForSelect?.length,
      value: state.supplierAccountValue,
      options: suppliersAccountForSelect,
      onChangeSelect: onChangeSupplierAccountHandler,
    },
    {
      type: 'select',
      label: 'Category',
      isDisabled: categoriesForSelect?.length === 0,
      value: state.categoryValue,
      options: categoriesForSelect,
      onChangeSelect: onChangeCategoryFieldHandler,
    },
    {
      type: 'date',
      label: 'Date',
      value: state.dateValue,
      onChangeDate: onChangeDate,
    },
    {
      type: 'select',
      label: 'Currency',
      value: state.currencyValue,
      options: formatedCurrencies,
      onChangeSelect: onChangeCurrencyFieldHandler,
    },
    // {
    //   type: 'input',
    //   label: 'VAT Rate',
    //   value: state.vatCodeValue,
    //   onChange: onChangeVatCodeFieldHandler,
    // },
    {
      type: 'input',
      inputType: 'number',
      label: 'Net',
      value: state.netValue || '',
      onChange: onChangeNetFieldHandler,
    },
    {
      type: 'input',
      inputType: 'number',
      label: 'Tax',
      value: state.taxValue || '',
      onChange: onChangeTaxFieldHandler,
    },
    {
      type: 'input',
      inputType: 'number',
      label: 'Total',
      value: state.totalValue || '',
      onChange: onChangeTotalFieldHandler,
    },
    {
      type: 'select',
      label: 'Payment Type',
      isDisabled: !typesForSelect?.length,
      value: state.paymentTypeValue,
      options: typesForSelect,
      onChangeSelect: onChangePaymentTypeFieldHandler,
    },
    // {
    //   type: 'input',
    //   label: 'Description',
    //   value: state.descriptionValue,
    //   onChange: onChangeDescriptionFieldHandler,
    //   isTextArea: true,
    // },
    // {
    //   type: 'checkbox',
    //   label: 'Payment status',
    //   labelText: 'Paid',
    //   value: state.paymentStatus,
    //   onChangeCheckbox: onChangePaymentStatus,
    // },
  ];

  returnTypeFunction(inputFields);
  // type custom = typeof inputFields;
  // interface IFieldsInput { custom: custom };

  const datePickerRef = useRef<HTMLButtonElement>(null);

  return {
    selectedReceipt,
    imageSrc,
    receipts,
    selectedReceiptIndex,
    isImageLoading,
    isPDF,
    onFetchReceiptImageHandler,
    onGoBackHandler,
    onClickGetNextReceiptHandler,
    onClickGetPrevReceiptHandler,

    // =======================>
    ...state,
    isOpen,
    isLoading,
    inputFields,
    datePickerRef,  //    ButtonValue,
    onChangeStateFieldHandler,
    onClickOutsideDatePickerHandler,
    onDatePickerClickHandler,
    onChangeRadioButtonHandler,
    onCancelButtonClickHandler,
    saveReceiptHandler,
    onGetAllMasterItemsHandler,
    onForbiddenCharacterClick,
  };
};


 // const onGetReceiptImageHandler = async () => {
  //   try {
  //     setImageLoading(true);
  //     selectedReceipt?.photos[0].match(/.pdf/g) ? setIsPDF(true) : setIsPDF(false);
  //     console.warn('----', selectedReceipt);
  //     const { data } = await getReceiptImage(
  //       selectedReceipt?.photos[0] || '',
  //       token,
  //       active_account || ''
  //     );
  //     // console.warn('receipt view -------', data);
  //     setImageSrc(URL.createObjectURL(data) || '');
  //     setImageLoading(false);
  //   } catch (error) {
  //     console.log(error);
  //     setImageLoading(false);
  //   } finally {
  //     setImageLoading(false);
  //   }
  // };
