// import React from 'react';

// import { Styled } from './Input.style';
// import { ErrorText } from '../ErrorText';
// import { CustomDatePicker } from '../CustomDatePicker';

// interface InputProps {
//   isRemoveBorder?: boolean;
//   inputHeight?: string;
//   isTextArea?: boolean;
//   isNoMargin?: boolean;
//   inputName?: string;
//   errorText?: string;
//   placeHolder?: string;
//   inputTheme?: 'search';
//   onBlur?: (event: React.ChangeEvent<HTMLInputElement>) => void;
//   isRemoveBoxShadow?: boolean;
//   touched?: boolean;
//   isHiddenLabel?: boolean;
//   text?: string;
//   textCode?:string;
//   onChangeValue?: (
//     event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
//   ) => void;
//   onKeyDown?: (event: React.KeyboardEvent) => void;
//   value: string;
//   isInputDate?: boolean;
//   selectedDate?: Date | null;
//   inputType?: string;
//   onFocus?: () => void;
//   isDisabled?: boolean;
// }

// export const Input: React.FC<InputProps> = (props) => {
//   const {
//     text,
//     inputName,
//     textCode,
//     inputTheme,
//     isTextArea,
//     inputHeight,
//     value,
//     errorText,
//     touched,
//     isHiddenLabel,
//     isNoMargin,
//     placeHolder,
//     isInputDate,
//     selectedDate,
//     inputType,
//     isDisabled,
//     isRemoveBoxShadow,
//     isRemoveBorder,
//     onChangeValue,
//     onKeyDown,
//     onBlur,
//     onFocus,
//   } = props;
//   return (
//     <Styled.InputWrapper data-testid="input-component" isNoMargin={isNoMargin}>
//       {isHiddenLabel ? null : <Styled.Label>{text}{textCode}</Styled.Label>}
//       {isTextArea ? (
//         <Styled.TextArea
//           inputHeight={inputHeight}
//           onChange={onChangeValue}
//           value={value}
//           name={inputName}
//         />
//       ) : (
//         <>
//           {isInputDate ? (
//             <CustomDatePicker
//               isInputDate={isInputDate}
//               selectedDate={selectedDate}
//               formattedDate=""
//             />
//             ) : (
//                 <>
//                 {isOptionInput ? (
                
//                 ):(
//                 </>
//             <Styled.Input
//               data-testid="input-field"
//               inputTheme={inputTheme}
//               type={inputType || 'text'}
//               isError={!!errorText && touched}
//               onBlur={onBlur}
//               onFocus={onFocus}
//               name={inputName}
//               value={value}
//               onChange={onChangeValue}
//               onKeyDown={onKeyDown}
//               placeholder={placeHolder}
//               disabled={isDisabled}
//               isDisabled={isDisabled}
//               isRemoveBoxShadow={isRemoveBoxShadow}
//               isRemoveBorder={isRemoveBorder}
//             />
//           )}
//         </>
//       )}
//       {touched && !!errorText && <ErrorText errorText={errorText} />}
//     </Styled.InputWrapper>
//   );
// };

//--------------------------------------------------------------------------------------
// import React from 'react';
// import { Styled } from './Input.style';
// import { ErrorText } from '../ErrorText';
// import { CustomDatePicker } from '../CustomDatePicker';
// interface DataItem {
//   id: string;
//   label: string;
//   value: string;
// }
// interface InputProps {
//   isRemoveBorder?: boolean;
//   inputHeight?: string;
//   isTextArea?: boolean;
//   isNoMargin?: boolean;
//   inputName?: string;
//   errorText?: string;
//   placeHolder?: string;
//   inputTheme?: 'search';
//   onBlur?: (event: React.ChangeEvent<HTMLInputElement>) => void;
//   isRemoveBoxShadow?: boolean;
//   touched?: boolean;
//   isHiddenLabel?: boolean;
//   text?: string;
//   textCode?: string;
//   onChangeValue?: (
//     event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
//   ) => void;
//   onChangeDropDownValue?: (
//     event: React.ChangeEvent<HTMLSelectElement>
//   ) => void;
//   onKeyDown?: (event: React.KeyboardEvent) => void;
//   value: string;
//   isInputDate?: boolean;
//   selectedDate?: Date | null;
//   inputType?: string;
//   onFocus?: () => void;
//   isDisabled?: boolean;
//   isOptionInput?: boolean;
//   isDropdown?: boolean; // Added this prop
//   dropdownOptions?: (() => Promise<DataItem[] | undefined>) | undefined; // Added this prop
// }

// export const Input: React.FC<InputProps> = (props) => {
//   const {
//     text,
//     inputName,
//     textCode,
//     inputTheme,
//     isTextArea,
//     inputHeight,
//     value,
//     errorText,
//     touched,
//     isHiddenLabel,
//     isNoMargin,
//     placeHolder,
//     isInputDate,
//     selectedDate,
//     inputType,
//     isDisabled,
//     isRemoveBoxShadow,
//     isRemoveBorder,
//     onChangeValue,
//     onKeyDown,
//     onBlur,
//     onFocus,
//     isOptionInput,
//     isDropdown, // Added this destructured prop
//     dropdownOptions,
//     onChangeDropDownValue// Added this destructured prop
//   } = props;

//   return (
//     <Styled.InputWrapper data-testid="input-component" isNoMargin={isNoMargin}>
//       {!isHiddenLabel && (
//         <Styled.Label>{text}{textCode}</Styled.Label>
//       )}

//       {isTextArea ? (
//         <Styled.TextArea
//           inputHeight={inputHeight}
//           onChange={onChangeValue}
//           value={value}
//           name={inputName}
//         />
//       ) : (
//         <>
//           {isInputDate ? (
//             <CustomDatePicker
//               isInputDate={isInputDate}
//               selectedDate={selectedDate}
//               formattedDate=""
//             />
//             ) : isDropdown ? (
//             <Styled.Select   //drop down add here
//               data-testid="dropdown-field"
//               value={value}
//               name={inputName}
//               onChange={onChangeDropDownValue}
//               disabled={isDisabled}
//             >
//               {dropdownOptions?.map((option:any) => (
//                 <option key={option.label +"-"+option.value} value={option.value}>
//                   {option.label}
//                 </option>
//               ))}
//             </Styled.Select>
//               ) :
//                  (
//             <Styled.Input
//               data-testid="input-field"
//               inputTheme={inputTheme}
//               type={inputType || 'text'}
//               isError={!!errorText && touched}
//               onBlur={onBlur}
//               onFocus={onFocus}
//               name={inputName}
//               value={value}
//               onChange={onChangeValue}
//               onKeyDown={onKeyDown}
//               placeholder={placeHolder}
//               disabled={isDisabled}
//               isRemoveBoxShadow={isRemoveBoxShadow}
//               isRemoveBorder={isRemoveBorder}
//             />
//           )}
//         </>
//       )}

//       {touched && !!errorText && <ErrorText errorText={errorText} />}
//     </Styled.InputWrapper>
//   );
// };

//===========================================================================================

import React from 'react';
import { Styled } from './Input.style';
import { ErrorText } from '../ErrorText';
import { CustomDatePicker } from '../CustomDatePicker';
// import { PhotoDetailsContent } from 'components/PhotoDetails/PhotoDetailsContent/PhotoDetailsContent';
// import { usePhotoDetailsContentState } from 'components/ExpenseContent/ExpenseDetailsContent.state';

interface DataItem {
  id: string;
  label: string;
  value: string;
}

interface InputProps {
  isRemoveBorder?: boolean;
  inputHeight?: string;
  isTextArea?: boolean;
  isNoMargin?: boolean;
  inputName?: string;
  errorText?: string;
  placeHolder?: string;
  inputTheme?: 'search';
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  isRemoveBoxShadow?: boolean;
  touched?: boolean;
  isHiddenLabel?: boolean;
  text?: string;
  textCode?: string;
  onChangeValue?: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  onChangeDropDownValue?: (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => void;
  onKeyDown?: (event: React.KeyboardEvent) => void;
  value: string;
  isInputDate?: boolean;
  selectedDate?: Date | null;
  inputType?: string;
  onFocus?: () => void;
  isDisabled?: boolean;
  isOptionInput?: boolean;
  //date picker types
  onChangeDate?: ((date: Date) => void) | undefined;
  isDatePickerOpen?: boolean | undefined;
  formattedDate?: ((date: Date) => void) | string;
  onDatePickerClickHandler?: ((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) | undefined;
  onClickOutsideDatePickerHandler?: ((event: React.MouseEvent<HTMLDivElement>) => void) | undefined;
  datePickerRef?: React.RefObject<HTMLButtonElement> | undefined;
}
export const Input: React.FC<InputProps> = (props) => {
  const {
    text,
    inputName,
    textCode,
    inputTheme,
    isTextArea,
    inputHeight,
    value,
    errorText,
    touched,
    isHiddenLabel,
    isNoMargin,
    placeHolder,
    isInputDate,
    selectedDate,
    inputType,
    isDisabled,
    isRemoveBoxShadow,
    isRemoveBorder,
    onChangeValue,
    onKeyDown,
    onBlur,
    onFocus,
    //date picker 
    isDatePickerOpen,
    formattedDate,
    onDatePickerClickHandler,
    onClickOutsideDatePickerHandler,
    datePickerRef,
    onChangeDate
  } = props;

  // const { onChangeDate } = usePhotoDetailsContentState;
  // const onChangeDate = (date: Date) => {
  //   setIsOpen(!isOpen);
  //   setState((prevState) => ({
  //     ...prevState,
  //     dateValue: date,
  //     formattedDate: format(date, company.date_format || DATE_FORMATS[0].value),
  //   }));
  // };
  return (
    <Styled.InputWrapper data-testid="input-component" isNoMargin={isNoMargin}>
      {!isHiddenLabel && (
        <Styled.Label>{text}{textCode}</Styled.Label>
      )}

      {isTextArea ? (
        <Styled.TextArea
          inputHeight={inputHeight}
          onChange={onChangeValue}
          value={value}
          name={inputName}
        />
      ) : (
        <>
            {isInputDate ? (
            // <CustomDatePicker
            //   isInputDate={isInputDate}
            //   selectedDate={selectedDate}
            //   formattedDate=""
            //   />
              <CustomDatePicker
                isInputDate={true}
              selectedDate={selectedDate}
                isDatePickerOpen={isDatePickerOpen}
                formattedDate={formattedDate || ''}
              onDatePickerClickHandler={onDatePickerClickHandler}
              onClickOutsideDatePickerHandler={onClickOutsideDatePickerHandler}
              datePickerRef={datePickerRef}
              onChange={onChangeDate}
            />
                )
            //   : isDropdown ? (
            // <Styled.Select
            //   data-testid="dropdown-field"
            //   value={value}
            //   name={inputName}
            //   onChange={onChangeDropDownValue}
            //   disabled={isDisabled}
            // >
            //   {dropdownOptions?.map(option => (
            //     <option key={option.label} >
            //      {option.label} 
            //     </option>
            //   ))}
            //       {/* <p>{dropdownOptions.values}</p> */}
            // </Styled.Select>
            //   )
                : (
            <Styled.Input
              data-testid="input-field"
              inputTheme={inputTheme}
              type={inputType || 'text'}
              isError={!!errorText && touched}
              onBlur={onBlur}
              onFocus={onFocus}
              name={inputName}
              value={value}
              onChange={onChangeValue}
              onKeyDown={onKeyDown}
              placeholder={placeHolder}
              disabled={isDisabled}
              isRemoveBoxShadow={isRemoveBoxShadow}
              isRemoveBorder={isRemoveBorder}
            />
          )}
        </>
      )}

      {touched && !!errorText && <ErrorText errorText={errorText} />}
    </Styled.InputWrapper>
  );
};
