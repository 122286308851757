export const permissionsData = [
  {
    id: 1,
    name: 'Dashboard',
    value: "Full",
    adminDefault: "Full",
    userDefault: "View",
    accountantDefault: "Full",
  },
  {
    id: 2,
    name: 'Purchases',
    value: "Full",
    adminDefault: "Full",
    userDefault: "View",
    accountantDefault: "Full",
  },
  {
    id: 3,
    name: 'Sales',
    value: "Full",
    adminDefault: "Full",
    userDefault: "View",
    accountantDefault: "Full",

  },
  {
    id: 4,
    name: 'Expense Report',
    value: "Full",
    adminDefault: "Full",
    userDefault: "View",
    accountantDefault: "Full",

  },
  {
    id: 5,
    name: 'Manage',
    value: "Full",
    adminDefault: "Full",
    userDefault: "View",
    accountantDefault: "Full",
    expandStatus: true,
    subPermissions: [
      { id: 51, name: 'Suppliers', value: "Full", adminDefault: "Full",userDefault: "View", accountantDefault: "Full",},
      { id: 52, name: 'Suppliers Accounts', value: "Full", adminDefault: "Full",userDefault: "View", accountantDefault: "Full", },
      { id: 53, name: 'Categories', value: "Full", adminDefault: "Full",userDefault: "View", accountantDefault: "Full", },
      { id: 54, name: 'Customers', value: "Full", adminDefault: "Full",userDefault: "View", accountantDefault: "Full", },
      { id: 55, name: 'Customers Accounts', value: "Full", adminDefault: "Full",userDefault: "View", accountantDefault: "Full", },
      { id: 56, name: ' Payment Methods', value: "Full", adminDefault: "Full",userDefault: "View", accountantDefault: "Full", },
    ],
  },
  {
    id: 6,
    name: 'Settings',
    value: "Full",
    adminDefault: "Full",
    userDefault: "View",
    accountantDefault: "Full",
    expandStatus: true,
    disable:true,
    subPermissions: [
      { id: 61, name: 'My Account', value: "Full", adminDefault: "Full",userDefault: "View", accountantDefault: "Full",disable:true},
      { id: 62, name: 'Companies', value: "Full" , adminDefault: "Full",userDefault: "View", accountantDefault: "Full",},
      { id: 63, name: 'Users', value: "Full", adminDefault: "Full",userDefault: "View", accountantDefault: "Full", },
      { id: 64, name: 'Term of Services', value: "Full" , adminDefault: "Full",userDefault: "View", accountantDefault: "Full", disable:true},
      { id: 65, name: 'Privacy Policy', value: "Full", adminDefault: "Full",userDefault: "View", accountantDefault: "Full", disable:true},
      { id: 66, name: 'Delete Account', value: "Full", adminDefault: "Full",userDefault: "View", accountantDefault: "Full", },
    ],
  },
];

export const permissionsResetData = [
  {
    id: 1,
    name: 'Dashboard',
    value: "Full",
    adminDefault: "Full",
    userDefault: "View",
    accountantDefault: "Full",
  },
  {
    id: 2,
    name: 'Purchases',
    value: "Full",
    adminDefault: "Full",
    userDefault: "View",
    accountantDefault: "Full",
  },
  {
    id: 3,
    name: 'Sales',
    value: "Full",
    adminDefault: "None",
    userDefault: "View",
    accountantDefault: "Full",

  },
  {
    id: 4,
    name: 'Expense Report',
    value: "Full",
    adminDefault: "Full",
    userDefault: "View",
    accountantDefault: "Full",

  },
  {
    id: 5,
    name: 'Manage',
    value: "",
    adminDefault: "Full",
    userDefault: "View",
    accountantDefault: "Full",
    expandStatus: true,
    subPermissions: [
      {
        id: 51, name: 'Suppliers', value: "Full", adminDefault: "Full",userDefault: "View", accountantDefault: "Full",
      },
      { id: 52, name: 'Suppliers Accounts', value: "Full", adminDefault: "Full",userDefault: "View", accountantDefault: "Full", },
      { id: 53, name: 'Categories', value: "Full", adminDefault: "Full",userDefault: "View", accountantDefault: "Full", },
      { id: 54, name: 'Customers', value: "Full", adminDefault: "Full",userDefault: "View", accountantDefault: "Full", },
      { id: 55, name: 'Customers Accounts', value: "Full", adminDefault: "Full",userDefault: "View", accountantDefault: "Full", },
      { id: 56, name: ' Payment Methods', value: "Full", adminDefault: "Full",userDefault: "View", accountantDefault: "Full", },
    ],
  },
  {
    id: 6,
    name: 'Settings',
    value: "Full",
    adminDefault: "Full",
    userDefault: "View",
    accountantDefault: "Full",
    expandStatus: true,
    disable:true,
    subPermissions: [
    
    { id: 61, name: 'My Account', value: "Full", adminDefault: "Full",userDefault: "View", accountantDefault: "Full",disable:true },
      { id: 62, name: 'Companies', value: "Full" , adminDefault: "Full",userDefault: "View", accountantDefault: "Full"},
      { id: 63, name: 'Users', value: "Full", adminDefault: "Full",userDefault: "View", accountantDefault: "Full", },
      { id: 64, name: 'Term of Services', value: "Full" , adminDefault: "Full",userDefault: "View", accountantDefault: "Full",disable:true},
      { id: 65, name: 'Privacy Policy', value: "Full", adminDefault: "Full",userDefault: "View", accountantDefault: "Full",disable:true },
      { id: 66, name: 'Delete Account', value: "Full", adminDefault: "Full",userDefault: "View", accountantDefault: "Full", },
    ],
  },
];