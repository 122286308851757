import { persistStore, persistReducer } from 'redux-persist';
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { rootReducer } from './reducer';

import storage from 'redux-persist/lib/storage';
import storageSession from 'redux-persist/lib/storage/session';

const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: false,
  }),
  // devTools: process.env.NODE_ENV !== 'production',
  devTools: false,
});

const persistConfig = {
  key: 'root',
  storage,
}

const persistedReducer = persistReducer(persistConfig, rootReducer);

const persisterStore = persistStore(store);
export { persisterStore, store };
export type RootState = ReturnType<typeof store.getState>;
export type TypeStore = typeof store;
