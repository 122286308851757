import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { IState } from 'services/redux/reducer';

import { setIsFetchingData } from '../Inbox/reducer/inbox.reducer';
import { receiptCreate, salesCreate, s3Create, s3Upload, getUserID } from './filesUploadPreview.api';
import {
  resetState,
  setActiveIndex,
} from './reducer/filesUploadPreview.reducer';
import { LocationState, loany } from './types/filesUploadPreview.types';
import { INITIAL_STATE } from './filesUploadPreview.constants';

import { ROUTES } from 'constants/routes';
// import { getReceipts } from '../Inbox/inbox.api';
// import { updateReceiptItem } from '../ReceiptDetails/receiptDetails.api';

// import { s3Ack, s3Listen } from 'services/s3Socket';
// import { useSocketIO } from 'hooks/useSocketIO';
// import { useDashboardState } from 'screens/Dashboard/Dashboard.state';

export const useFilesUploadPreviewState = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const onNavigateToInboxPage = () => navigate(ROUTES.purchaseInvoices);

  // const { from } = location.state as LocationState;
  const { from } = location.state as loany;
  // console.log(location, from);
  const actionField = from.state.action;

  const [isLoading, setIsLoading] = useState(false);

  // const { s3Ack } = useSocketIO();
  // const { isS3Event, setIsS3Event } = useDashboardState();

  const {
    filesUpload: { previewFiles, filesArray, activeIndex },
    user: { token, user },
    inbox: { s3EventStatus },
    socket: { socketSingleTon }
  } = useSelector((state: IState) => state);

  const [state, setState] = useState(INITIAL_STATE);

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      currentFileName: previewFiles[activeIndex]?.fileName,
      currentFileSrc: previewFiles[activeIndex]?.fileSrc,
      curretFileType: previewFiles[activeIndex]?.fileType,
    }));
  }, [previewFiles]);

  const onChooseReceiptHandler = (
    fileName: string,
    fileSrc: string,
    fileType: string
  ) => {
    setState((prevState) => ({
      ...prevState,
      currentFileName: fileName,
      currentFileSrc: fileSrc,
      curretFileType: fileType,
    }));
    dispatch(setActiveIndex(fileName));
  };

  const onGoBackHandler = () => navigate(-1);

  const onCancelClickHandler = () => {
    dispatch(resetState());
    navigate(from.state.action === 'receipt' ? '/purchase-invoices' : '/sales-invoices');
  };

  const s3UploadHandler = async () => {
    try {
      setIsLoading(true);

      dispatch(resetState());
      navigate(from.state.action === 'receipt' ? '/purchase-invoices' : '/sales-invoices', { replace: true });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      dispatch(setIsFetchingData(false));
      console.log(error);
    }
  };
  const onSaveClickHandler = async () => {
    try {
      setIsLoading(true);
      const formData = new FormData();

      filesArray.forEach((file) => {
        formData.append('receipt_photos', file);
        console.warn(formData);
      });
      formData.append('active_account', user.active_account || '');
      await receiptCreate(formData, token);

      dispatch(setIsFetchingData(true));
      dispatch(resetState());
      setIsLoading(false);
      navigate(ROUTES.purchaseInvoices, { replace: true });
    } catch (error) {
      setIsLoading(false);
      dispatch(setIsFetchingData(false));
      console.log(error);
    }
  };

  const onCreateSalesHandler = async () => {
    try {
      setIsLoading(true);
      const formData = new FormData();

      filesArray.forEach((file) => {
        formData.append('receipt_photos', file);
      });
      formData.append('active_account', user.active_account || '');
      await salesCreate(formData, token);

      dispatch(setIsFetchingData(true));
      dispatch(resetState());
      setIsLoading(false);
      navigate(from.state.action === 'receipt' ? '/purchase-invoices' : '/sales-invoices', { replace: true });
    } catch (error) {
      setIsLoading(false);
      dispatch(setIsFetchingData(false));
      console.log(error);
    }
  };

  const isDisableButton = previewFiles.length > 50;

  return {
    ...state,
    previewFiles,
    filesArray,
    isLoading,
    isDisableButton,
    onChooseReceiptHandler,
    onNavigateToInboxPage,
    onGoBackHandler,
    onCancelClickHandler,
    onSaveClickHandler,
    onCreateSalesHandler,
    s3UploadHandler,
    actionField,
  };
};
