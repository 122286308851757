import { FC, useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";

import { EmptyData } from "components/EmptyData";
import { LoaderComponent } from "components/Loader";
import { EMPTY_DATA_SALES_STRINGS as Strings } from "constants/strings";

import { SalesInvoicesStyles as Styled } from "./SalesInvoices.styles";
import { SalesInvoicesTable } from "components/Table/SalesInvoices";

import { CSVLink } from "react-csv";
import { EmailModalWindow } from "components/EmailModalWindow";
import { SuccessPopup } from "components/SuccessPopup";

import { useSalesInvoicesState } from "./SalesInvoices.state";
import { PaginationPanel } from "components/PaginationPanel";

import { ReUseActionButton } from "../../ReUseComponents/reUseActionButton/ReUseActionButton";
import { ReUseSearch } from "ReUseComponents/reUseSearch/ReUseSearch";
import { ReUseActionMenu } from "ReUseComponents/reUseActionMenu/ReUseActionMenu";
import { ROUTES } from "constants/routes";
import { ReUseDatePicker } from "ReUseComponents/reUseDatePicker/ReuseDatePicker";
import { ReUseStatusFilter } from "ReUseComponents/reUseStatusFilter/ReUseStatusFilter";
import { Is3Event } from "screens/Inbox/types/inbox.types";
import { UploadStatus } from "components/UploadStatus/UploadStatus";

export const SalesInvoices: FC = () => {
	const navigate = useNavigate();
	const [s3InvoiceStatus, setS3InvoiceStatus] = useState<Is3Event[]>([]);
	const {
		userRole,
		onBackwardClick,
		onForwardClick,
		onGoToClick,
		onEnterGoToClick,
		onChangePaginationInputValue,
		onChangePagesAmount,
		onChangePageHandler,
		setItemsPerPage,
		setCurrentPage,
		itemsPerPage, //: receiptsPerPage,
		currentPage,
		pages,
		inputPaginationValue,
		onChangePage,

		sortOrder,
		sortField,
		requestSort,
		onCheckedPublishMockFuncHandler,
		onCheckedPaidHandler,
		onCheckedApproveHandler,
		checkedInvoiceIds,
		isAllChecked,
		onDeleteInvoiceHandler,
		datePickerRef,
		excelRef,
		excelUrl,
		csvData,
		isSentSuccessPopup,
		csvLink,
		dataToSend,
		dateEnd,
		dateStart,
		datePickerValue,
		datePickerRangeValue,
		debouncedValue,
		fetchParams,
		formattedDate,
		isInputDate,
		isCompanyChanged,
		isContentLoading,
		isDatePickerOpen,
		isActionMenuDisabled,
		isEmailModalWindowOpen,
		isLoading,
		location,
		onActionsClick,
		onActionsClose,
		onChangeDate,
		onChangeItemsPerPage,
		onChangeSearchValueHandler,
		onChangeStatusValueHandler,
		onChangeDateFilterValueHandler,
		onCheckedItemHandler,
		onClickDownloadCSVButtonHandler,
		onClickOutsideDatePickerHandler,
		onCloseModalWindowHandler,
		onDownloadExcelFileHandler,
		onEmailClick,
		InvoiceActionList,
		onEmailModalWindowToggle,
		onCloseEmailModalHandler,
		onMarkAsPaidButtonHandler,
		onPostEmailHandler,
		onSelectSalesFilesHandler,
		searchValue,
		setIsDatePickerOpen,
		setIsSentSuccessPopup,
		showActions,
		sortedInvoices,
		statusValue,
		dateFilterValue,
		totalCount,
		invoice_formik_mail,
		onMarkAsHandler,
		onFocusSearchHandler,
		onBlurHandler,

		invoicesList,
		onFetchSalesInvoicesHandler,
		active_account,
		count,
		isFetchingInvoice,
		onCheckedAllItemsHandler,
		company,
		s3EventStatus,
	} = useSalesInvoicesState();

	const isGuard = true;
	// const isEmptyScreen = !isFetchingInvoice && !totalCount;
	// console.log(isFetchingInvoice, totalCount);
	console.warn('--==--', invoicesList);

	useEffect(() => {
		onFetchSalesInvoicesHandler({
			...fetchParams,
			skip: 0,
		});
		if (debouncedValue || isCompanyChanged) {
			setCurrentPage(0);
		}
	}, [debouncedValue, active_account]);

	useEffect(() => {
		if (count) {
			onChangePagesAmount(Number(itemsPerPage.value), count);
		}
	}, [itemsPerPage, count, active_account]);

	useEffect(() => {
		setS3InvoiceStatus(s3EventStatus.filter((estatus) => estatus.field === "invoice"));
	}, [s3EventStatus.length]);

	return (
		<>
			{location.pathname !== "/sales-invoices" ? (
				<Outlet />
			) : isFetchingInvoice ? (
				<Styled.LoaderWrapper>
					<LoaderComponent theme="preview" />
				</Styled.LoaderWrapper>
			) : (!isFetchingInvoice && totalCount) || s3InvoiceStatus.length ? (
				<Styled.Wrapper>
					<Styled.ActionPanelPlaceHolder>
						<ReUseSearch searchValue={searchValue} onChangeSearchValueHandler={onChangeSearchValueHandler} onBlurHandler={onBlurHandler} onFocusSearchHandler={onFocusSearchHandler} />
						<ReUseDatePicker
							datePickerRef={datePickerRef}
							dateFilterValue={dateFilterValue}
							isDatePickerOpen={isDatePickerOpen}
							dateValue={datePickerValue}
							formattedDate={formattedDate}
							isInputDate={isInputDate}
							onChangeDate={onChangeDate}
							setIsDatePickerOpen={setIsDatePickerOpen}
							onChangeDateFilterValueHandler={onChangeDateFilterValueHandler}
							onClickOutsideDatePickerHandler={onClickOutsideDatePickerHandler}
						/>
						<ReUseStatusFilter onChangeStatusValueHandler={onChangeStatusValueHandler} statusValue={statusValue} />
						{s3InvoiceStatus?.length ? <UploadStatus margin="0 0 0 auto" s3EventStatus={s3InvoiceStatus} onFetchDataHandler={onFetchSalesInvoicesHandler} fieldFilter="invoice" /> : null}
						<ReUseActionMenu margin={s3InvoiceStatus.length > 0 ? "0" : "0 0 0 auto"} actionListArray={InvoiceActionList} isActionMenuDisabled={isActionMenuDisabled} />
						{isGuard && userRole !== "user" && <ReUseActionButton displayText="Upload Invoices" buttonType="actionButton" widthType="primary" themedButton="primary" toPath={ROUTES.invoiceUploadFile} locationState={{ action: "invoice" }} displayIconType="addPlus" />}
					</Styled.ActionPanelPlaceHolder>
					<SalesInvoicesTable
						onCheckedPublishMockFuncHandler={onCheckedPublishMockFuncHandler}
						invoicesList={sortedInvoices}
						sortedInvoices={sortedInvoices}
						onCheckedItemHandler={onCheckedItemHandler}
						isAllChecked={isAllChecked}
						onCheckedPaidHandler={onCheckedPaidHandler}
						onCheckedApproveHandler={onCheckedApproveHandler}
						dateFormat={company.date_format}
						sortField={sortField}
						sortOrder={sortOrder}
						requestSort={requestSort}
						onCheckedAllItemsHandler={onCheckedAllItemsHandler}
						isContentLoading={isContentLoading}
					/>
					<PaginationPanel
						pages={pages}
						currentPage={currentPage}
						onChangeItemsPerPage={onChangeItemsPerPage}
						onChangePaginationInputValue={onChangePaginationInputValue}
						inputPaginationValue={inputPaginationValue}
						itemsPerPage={itemsPerPage}
						onChangePage={onChangePage}
						onEnterGoToClick={onEnterGoToClick}
						onGoToClick={onGoToClick}
						onForwardClick={onForwardClick}
						onBackwardClick={onBackwardClick}
						paginationCustomStyle={{
							position: "fixed",
						}}
					/>
				</Styled.Wrapper>
			) : !isFetchingInvoice && !totalCount && !s3InvoiceStatus.length ? (
				<EmptyData
					isUploadFile={true}
					// title={salesEmptySet().title}
					// firstSubtitle={salesEmptySet().firstSubtitle}
					title={Strings.title}
					// firstSubtitle={purchaseEmptySet().firstSubtitle}
					firstSubtitle={Strings.firstSubtitle}
					secondSubtitle={Strings.secondSubtitle}
				>
					<ReUseActionButton displayText="Upload Invoices" buttonType="actionButton" widthType="primary" themedButton="primary" toPath={ROUTES.invoiceUploadFile} locationState={{ action: "invoice" }} displayIconType="addPlus" />
				</EmptyData>
			) : null}

			<Styled.CSVLinkWrapper>
				<CSVLink data={csvData} filename="receipt.csv" ref={csvLink} target="_blank" />
			</Styled.CSVLinkWrapper>
			<EmailModalWindow
				isModalWindowOpen={isEmailModalWindowOpen}
				onCloseModalWindowHandler={onCloseEmailModalHandler}
				formikMeta={invoice_formik_mail.getFieldMeta}
				formikProps={invoice_formik_mail.getFieldProps}
				onFormHandleSubmit={invoice_formik_mail.handleSubmit}
				checkedIds={checkedInvoiceIds}
				isValid={invoice_formik_mail.isValid && invoice_formik_mail.dirty}
				isLoading={isLoading}
			/>
			<Styled.ExcelLink href={excelUrl} ref={excelRef} />
			<SuccessPopup titleText="Email sent successfully" closePopupFc={setIsSentSuccessPopup} isShowPopup={isSentSuccessPopup} />
		</>
	);
};
