import { FC } from 'react';

import { CheckboxItem } from 'components/Checkbox';
import { CustomDatePicker } from 'components/CustomDatePicker';
import { CustomSelect } from 'components/CustomSelect';
import { Input } from 'components/Input';
// import { TFormFields } from 'screens/ReceiptDetails/receiptDetails.state';
import { TFieldsInput } from "screens/ReceiptDetails/receiptDetails.constant";

import { ExpenseDetailsContentItem } from '../ExpenseDetailsContentItem';

import { FiedlsBoxStyles as Styled } from './FiledsBox.style';
import { StatusBar } from 'components/StatusBar';

interface IFieldsBox {
  // inputFields: {type: string, label: string, value: string | Date | null | IOption, options?: IOption[], onChange?: () => void, onChangeSelect?: () => void, onChangeDate?: (date: Date) => void}[];
  // inputFields: TInputFields;
  // inputFields: TFieldsInput;
  inputFields: any[];
  onDatePickerClickHandler: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
  onClickOutsideDatePickerHandler: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => void;
  isOpen: boolean;
  formattedDate: string;
  datePickerRef: React.RefObject<HTMLButtonElement>;
  selectedDate: Date | null;
  onForbiddenCharacterClick: (event: React.KeyboardEvent<Element>) => void;
}

export const FieldsBox: FC<IFieldsBox> = (props) => {
  const {
    inputFields,
    datePickerRef,
    formattedDate,
    isOpen,
    selectedDate,
    onForbiddenCharacterClick,
    onClickOutsideDatePickerHandler,
    onDatePickerClickHandler,
  } = props;

  // const isInputField = (item: any): item is { inputType: string; value: string; isTextArea?: boolean } => {
  //   return item.type === 'input' || item.type === 'number';
  // };

  // const isCheckboxField = (item: any): item is { value: boolean; onChangeCheckbox: (e: any) => void; labelText: string } => {
  //   return item.type === 'checkbox';
  // };

  return (
    <>
      {inputFields.map((item) => (
        item?.label !== "Net" && item.label !== "Tax" && item.label !== "Total" && (
        <ExpenseDetailsContentItem key={item.label} label={item.label}>
          {item?.type && item.type === 'date' ? (
            <CustomDatePicker
              isInputDate
              onChange={item.onChangeDate}
              onDatePickerClickHandler={onDatePickerClickHandler}
              onClickOutsideDatePickerHandler={onClickOutsideDatePickerHandler}
              isDatePickerOpen={isOpen}
              selectedDate={selectedDate}
              formattedDate={formattedDate}
              datePickerRef={datePickerRef}
            />
          ) : item?.type && item.type === 'select' ? (
            <CustomSelect
              value={item.value}
              options={item.options}
              onChangeValueHandler={item.onChangeSelect}
              marginBottom="0px"
              isDisabled={item.isDisabled}
              isRemoveBorder
            />
          ) : item?.type && item.type === 'input' ? (
            <Input
              value={item.value}
              inputType={item.inputType}
              onChangeValue={item.onChange}
              isTextArea={item.isTextArea}
              isHiddenLabel
              isNoMargin
              isRemoveBorder
              onKeyDown={onForbiddenCharacterClick}
            />
          ) : item?.type && item.type === 'checkbox' ? (
            <Styled.CheckBoxWrapper>
              <CheckboxItem
                name={item.label}
                isChecked={item.value}
                labelText={item.labelText}
                onChange={item.onChangeCheckbox}
              />
            </Styled.CheckBoxWrapper>
          ) : item?.type && item.type === 'text' && item?.label === 'Status' ? (
            <StatusBar status={item?.value as TStatuses} />
          ) : item?.type && item.type === 'text' && item?.label === 'Receipt ID' ? (
            <StatusBar rid={item?.value} status={item?.status as TStatuses}/>
          ) : null}
        </ExpenseDetailsContentItem>
        )
      ))}
    </>
  );
};
