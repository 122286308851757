import { styled } from 'styles/theme';

import { IFormButtonPanelStyle } from './FormButtonPanel';

export const FormButtonPanelStyles = {
  Wrapper: styled.div<IFormButtonPanelStyle>`
    width: 100%;
    height: 60px;
    display: flex;
    gap: ${(props) => props.customFlexGap ? props.customFlexGap : '20px'};
    align-items: center;
    justify-content: space-between;
    position: ${(props) => props.position};
    z-index: 9;
    padding: ${(props) => props.padding ? props.padding : '0 15px'};
    bottom: ${(props) => props.bottom ? props.bottom : '0'};
    left: ${(props) => props.left ? props.left : '0'};
    right: ${(props) => props.right ? props.right : undefined};
    margin: ${(props) => props.margin ? props.margin : '0 0 0 0'};
    background-color: #fff;
    box-shadow:  0 -3px 10px #d2d2d2;
  `,
  Footer: styled.footer`
position: fixed;
bottom: 0;
left: 0;
width: 100%;
background-color: ${({ theme }) => theme.colors.white};
padding: 1rem;
box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
z-index: 11; /* Adjust the z-index as needed */
text-align: center;
border: 1px solid #0000000D;
max-height: 75px;
display:flex;
align-items:center;
  justify-content: space-between;
  gap: 10px;
`,
};
