import { FC, memo, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";

import { EmptyData } from "components/EmptyData";
import { LoaderComponent } from "components/Loader";
import { ROUTES } from "constants/routes";
import { theme } from "../../styles/theme";

import { InboxStyles as Styled } from "./Inbox.style";
import { useInboxState } from "./Inbox.state";

import { PaginationPanel } from "components/PaginationPanel";
import { TableInboxAdmin } from "components/Table/TableInboxAdmin";

import { CSVLink } from "react-csv";
import { EmailModalWindow } from "components/EmailModalWindow";
import { SuccessPopup } from "components/SuccessPopup";

import { ReUseSearch } from "ReUseComponents/reUseSearch/ReUseSearch";
import { ReUseDatePicker } from "ReUseComponents/reUseDatePicker/ReuseDatePicker";
import { ReUseStatusFilter } from "ReUseComponents/reUseStatusFilter/ReUseStatusFilter";
import { ReUseActionMenu } from "ReUseComponents/reUseActionMenu/ReUseActionMenu";
import { ReUseActionButton } from "ReUseComponents/reUseActionButton/ReUseActionButton";

import { EMPTY_DATA_STRINGS as Strings } from "constants/strings";
// import { useClientSocketIO } from 'hooks/useSocketIO';
import { ReUseActionPlaceholder } from "ReUseComponents/reUseActionPlaceHolder/ReUseActionPlaceHolder";
import { UploadStatus } from "components/UploadStatus/UploadStatus";
import { UploadingView } from "components/UploadStatus/UploadingView/UploadingView";
import { Is3Event } from "./types/inbox.types";

export const Inbox: FC = memo(() => {
	const [s3ReceiptStatus, setS3ReceiptStatus] = useState<Is3Event[]>([]);
	const {
		primaryAction,
		userRole,
		onSelectFilesHandler,
		onFetchReceiptsHandler,
		onChangeStatusValueHandler,
		onChangeDateFilterValueHandler,
		onChangeSearchValueHandler,
		onChangeDate,
		isDatePickerOpen,
		dateValue,
		searchValue,
		statusValue,
		dateFilterValue,
		formattedDate,
		isInputDate,
		setIsDatePickerOpen,
		isEmailModalWindowOpen,
		onEmailClick,
		formik,
		onChangeItemsPerPage,
		onChangePaginationInputValue,
		onEnterGoToClick,
		onGoToClick,
		onForwardClick,
		onBackwardClick,
		onActionsClick,
		onActionsClose,
		onChangePage,
		onCheckedItemHandler,
		onCheckedAllItemsHandler,
		onClickDownloadCSVButtonHandler,
		onCheckedPaidHandler,
		onCheckedApproveHandler,
		onCheckedPublishMockFuncHandler,
		// receiptsPerPage,
		itemsPerPage,
		inputPaginationValue,
		currentPage,
		pages,
		checkedIds,
		showActions,
		isAllChecked,
		csvLink,
		csvData,
		company,
		excelRef,
		excelUrl,
		isActionMenuButtonDisabled,
		isLoading,
		debouncedValue,
		isFetchingReceipts,
		location,
		datePickerRef,
		active_account,
		isSentSuccessPopup,
		setIsSentSuccessPopup,
		onCloseEmailModalHandler,
		onClickOutsideDatePickerHandler,
		onChangePagesAmount,
		onDownloadExcelFileHandler,
		onDeleteReceiptHandler,
		onMarkAsHandler,
		sortField,
		sortOrder,
		sortedReceipts,
		fetchParams,
		totalCount,
		isCompanyChanged,
		requestSort,
		setCurrentPage,
		count,
		paginationCustomStyle,
		InBoxActionList,
		onBlurHandler,
		onFocusSearchHandler,

		s3EventStatus,
	} = useInboxState();

	// const {
	//   socketEvent,
	// } = useClientSocketIO();

	const onFetchForFetch = () => {
		onFetchReceiptsHandler({
			...fetchParams,
			skip: 0,
		});
	};

	useEffect(() => {
		onFetchReceiptsHandler({
			...fetchParams,
			skip: 0,
		});
		if (debouncedValue || isCompanyChanged) {
			setCurrentPage(0);
		}
	}, [debouncedValue, active_account]);

	useEffect(() => {
		if (count) {
			onChangePagesAmount(Number(itemsPerPage.value), count);
		}
	}, [itemsPerPage, count, active_account]);

	useEffect(() => {
		setS3ReceiptStatus(s3EventStatus.filter((estatus) => estatus.field === "receipt"));
	}, [s3EventStatus]);

	return (
		<>
			{location.pathname !== "/purchase-invoices" ? (
				<Outlet />
			) : isFetchingReceipts ? (
				<Styled.LoaderWrapper>
					<LoaderComponent theme="preview" />
				</Styled.LoaderWrapper>
			) : (!isFetchingReceipts && totalCount) || s3ReceiptStatus.length ? (
				<Styled.Wrapper>
					<ReUseActionPlaceholder>
						<ReUseSearch searchValue={searchValue} onChangeSearchValueHandler={onChangeSearchValueHandler} onBlurHandler={onBlurHandler} onFocusSearchHandler={onFocusSearchHandler} />
						<ReUseDatePicker
							datePickerRef={datePickerRef}
							dateFilterValue={dateFilterValue}
							isDatePickerOpen={isDatePickerOpen}
							dateValue={dateValue}
							formattedDate={formattedDate}
							isInputDate={isInputDate}
							onChangeDate={onChangeDate}
							setIsDatePickerOpen={setIsDatePickerOpen}
							onChangeDateFilterValueHandler={onChangeDateFilterValueHandler}
							onClickOutsideDatePickerHandler={onClickOutsideDatePickerHandler}
						/>
						<ReUseStatusFilter onChangeStatusValueHandler={onChangeStatusValueHandler} statusValue={statusValue} />
						{s3ReceiptStatus?.length ? <UploadStatus margin="0 0 0 auto" s3EventStatus={s3ReceiptStatus} onFetchDataHandler={onFetchForFetch} fieldFilter="receipt" /> : null}
						<ReUseActionMenu margin={s3ReceiptStatus.length > 0 ? "0" : "0 0 0 auto"} actionListArray={InBoxActionList} isActionMenuDisabled={isActionMenuButtonDisabled} />
						{/* isGuard && */ userRole !== "user" ? <ReUseActionButton displayText="Upload Receipts" buttonType="actionButton" widthType="primary" themedButton="primary" toPath={ROUTES.receiptUploadFile} locationState={{ action: "receipt" }} displayIconType="addPlus" /> : null}
					</ReUseActionPlaceholder>
					<Styled.TableWrapper>
						{isLoading && (
							<Styled.LoaderWrapper>
								<LoaderComponent theme="preview" />
							</Styled.LoaderWrapper>
						)}
						<TableInboxAdmin
							onCheckedItemHandler={onCheckedItemHandler}
							onCheckedAllItemsHandler={onCheckedAllItemsHandler}
							onCheckedPaidHandler={onCheckedPaidHandler}
							onCheckedApproveHandler={onCheckedApproveHandler}
							onCheckedPublishMockFuncHandler={onCheckedPublishMockFuncHandler}
							receiptList={sortedReceipts}
							isAllChecked={isAllChecked}
							dateFormat={company.date_format}
							sortField={sortField}
							sortOrder={sortOrder}
							requestSort={requestSort}
							isLoading={isLoading}
							isFetchingReceipts
						/>
						{sortedReceipts?.length ? (
							<PaginationPanel
								pages={pages}
								currentPage={currentPage}
								onChangeItemsPerPage={onChangeItemsPerPage}
								onChangePaginationInputValue={onChangePaginationInputValue}
								inputPaginationValue={inputPaginationValue}
								itemsPerPage={itemsPerPage}
								onChangePage={onChangePage}
								onEnterGoToClick={onEnterGoToClick}
								onGoToClick={onGoToClick}
								onForwardClick={onForwardClick}
								onBackwardClick={onBackwardClick}
								paginationCustomStyle={paginationCustomStyle}
							/>
						) : null}
					</Styled.TableWrapper>
				</Styled.Wrapper>
			) : !isFetchingReceipts && !totalCount && !s3ReceiptStatus.length ? (
				<EmptyData
					isUploadFile={true}
					// buttonText={Strings.buttonText}
					// title={purchaseEmptySet().title}
					title={Strings.title}
					// firstSubtitle={purchaseEmptySet().firstSubtitle}
					firstSubtitle={Strings.firstSubtitle}
					secondSubtitle={Strings.secondSubtitle}
				>
					<ReUseActionButton
						displayText="Upload Receipts"
						/* buttonType="text-link" */ buttonType="actionButton"
						widthType="primary"
						themedButton="primary"
						toPath={ROUTES.receiptUploadFile}
						locationState={{ action: "receipt" }}
						displayIconType="addPlus" /* customColor={`${theme.colors.red}`} fontSize="22px" */
					/>
				</EmptyData>
			) : null}

			<Styled.CSVLinkWrapper>
				<CSVLink data={csvData} filename="receipt.csv" ref={csvLink} target="_blank" />
			</Styled.CSVLinkWrapper>
			<EmailModalWindow
				isModalWindowOpen={isEmailModalWindowOpen}
				onCloseModalWindowHandler={onCloseEmailModalHandler}
				formikMeta={formik.getFieldMeta}
				formikProps={formik.getFieldProps}
				onFormHandleSubmit={formik.handleSubmit}
				checkedIds={checkedIds}
				isValid={formik.isValid && formik.dirty}
				isLoading={isLoading}
			/>
			<Styled.ExcelLink href={excelUrl} ref={excelRef} />
			<SuccessPopup titleText="Email sent successfully" closePopupFc={setIsSentSuccessPopup} isShowPopup={isSentSuccessPopup} />
		</>
	);
});
