import React from "react";
import { format } from "date-fns";

import { Icon } from "components/Icons/Icons";

import { ItemSupplierStyle as Styled } from "./itemSupplier.style";

interface IItemSupplierProps {
  supplier: ITabItem;
  userRole: TRoles;
  dateFormat: string;
  onDeleteIconHandler: (itemID: string, index: number) => Promise<void>;
  onEditIconHandler: (itemID: string) => Promise<void>;
  key: number;
}

export const ItemSupplier: React.FC<IItemSupplierProps> = (props) => {
  const {
    supplier,
    dateFormat,
    userRole,
    onDeleteIconHandler,
    onEditIconHandler,
    key,
  } = props;
  const isActionDisabled = userRole === "user";
  console.log(supplier);
  return (
    <Styled.Item>
      <Styled.Column width="150">
        CU201
      </Styled.Column>
      <Styled.Column width="150">
        <Styled.NameWrapper>
          {supplier?.name || '----'}
        </Styled.NameWrapper>
      </Styled.Column>
      <Styled.Column width="150">
        <Styled.NameWrapper>
          {supplier?.vat?.value || "----"}
        </Styled.NameWrapper>
      </Styled.Column>
      <Styled.Column width="150">
        <Styled.NameWrapper>
          {supplier?.category?.name || '----'}
        </Styled.NameWrapper>
      </Styled.Column>
      <Styled.Column width="150">
        <Styled.NameWrapper>
          {'0'}
        </Styled.NameWrapper>
      </Styled.Column>
      <Styled.Column>
        {supplier.created && dateFormat ? format(new Date(supplier.created), dateFormat) : '----'}
      </Styled.Column>
      <Styled.Column width="200">
        {supplier.creator.name}
      </Styled.Column>
      <Styled.Action>
        <Styled.ActionButton
          isDisabled={isActionDisabled}
          onClick={() => onEditIconHandler(supplier.id)}
        >
          <Icon type="edit" />
        </Styled.ActionButton>
        <Styled.ActionButton
          isDisabled={isActionDisabled}
          onClick={() => onDeleteIconHandler(supplier.id, key)}
        >
          <Icon type="remove" />
        </Styled.ActionButton>
      </Styled.Action>
    </Styled.Item>
  );
};
