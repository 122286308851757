import { FC, useEffect, useState } from "react";

import { ReceiptDetailsHeader } from "components/ReceiptDetailsHeader";
import { PhotoPreview } from "components/PhotoPreview";
// import { PhotoDetailsContent } from "components/PhotoDetails/PhotoDetailsContent/PhotoDetailsContent";

import { ReceiptDetailsStyles as Styled } from "./invoiceDetails.style";
import { useInvoiceDeatilState } from "./invoiceDetails.state";
// import { usePhotoDetailsContentState } from "components/PhotoDetails/PhotoDetailsContent/PhotoDetailsContent.state";
import { CheckboxItem } from "components/Checkbox";
import { FieldsBox } from "components/ExpenseContent/FieldsBox";
// import { PhotoDetailsContentItem } from "components/PhotoDetails/PhotoDetailsContent/PhotoDetailsContentItem";
import { StatusBar } from "components/StatusBar";
import PurchaseTable from "components/Purchases/PurchasesTable";
// import { ButtonsBoxNew } from "components/PhotoDetails/ButtonBoxNew";
import { ButtonsBox } from "./ButtonsBox";
import { FormButtonPanel } from "components/FormButtonPanel";

export const InvoiceDetails: FC = () => {
	console.log("!!!!!!!!!!!!!!!!! - RD parent");
	const {
		onGoBackHandler,
		onClickGetNextReceiptHandler,
		onClickGetPrevReceiptHandler,
		onFetchReceiptImageHandler,
		imageSrc,
		selectedInvoice,
		invoicesList,
		selectedInvoiceIndex,
		isImageLoading,
		isPDF,
		inputFields,
		statusValue,
		paymentStatus,
		receiptid,
		dateValue,
		isOpen,
		isLoading,
		formattedDate,
		datePickerRef,
		saveInvoiceHandler,
		onChangeRadioButtonHandler,
		onCancelButtonClickHandler,
		onClickOutsideDatePickerHandler,
		onDatePickerClickHandler,
		onGetAllMasterItemsHandler,
		onForbiddenCharacterClick,
		publishedStatus,
		descriptionValue,
		onChangeStateFieldHandler,
	} = useInvoiceDeatilState();
	console.warn(inputFields);

	useEffect(() => {
		onFetchReceiptImageHandler();
	}, [selectedInvoice]);
	useEffect(() => {
		onGetAllMasterItemsHandler();
	}, []);

	return (
		<Styled.Section>
			<ReceiptDetailsHeader
				onClickGetNextReceiptHandler={onClickGetNextReceiptHandler}
				onClickGetPrevReceiptHandler={onClickGetPrevReceiptHandler}
				totalReceiptsCount={invoicesList?.length}
				currentReceiptPosition={Number(selectedInvoiceIndex) + 1}
				onGoBackHandler={onGoBackHandler}
				customId={selectedInvoice?.custom_id}
			/>
			<Styled.BodyWrapper>
				<PhotoPreview imageSrc={imageSrc} isImageLoading={isImageLoading} isPDF={isPDF} />
				<Styled.ReceiptDetailWrapper>
					{/* <PhotoDetailsContent changePaid={changePaid} fnChangePaid={fnChangePaid} actionValue={actionValue} fnGetPayStatus={getPayStatus} changePublish={changePublish} fnChangePublish={fnChangePublish} newPublish={newPublish} getLivePublish={getLivePublish} /> */}
					<Styled.ReceiptDetailContent>
						<Styled.FormFieldWrapper>
							<FieldsBox
								inputFields={inputFields}
								onDatePickerClickHandler={onDatePickerClickHandler}
								onClickOutsideDatePickerHandler={onClickOutsideDatePickerHandler}
								isOpen={isOpen}
								formattedDate={formattedDate}
								datePickerRef={datePickerRef}
								selectedDate={dateValue ? new Date(dateValue) : null}
								onForbiddenCharacterClick={onForbiddenCharacterClick}
							/>
						</Styled.FormFieldWrapper>
						<Styled.ReceiptItemTable>
							<h4>Details</h4>
							{/* {receiptItem ? receiptItem.items?.map((item) => <Styled.items>{item}</Styled.items>): 'no data'} */}
							<PurchaseTable inputFields={inputFields} />
						</Styled.ReceiptItemTable>
					</Styled.ReceiptDetailContent>
					<Styled.ReceiptStatusContainer>
						<Styled.CheckboxContainer>
							<CheckboxItem
								name={"Payment status"}
								isChecked={paymentStatus || false}
								labelText={"Mark as Paid"}
								onChange={(e) => onChangeStateFieldHandler("paymentStatus", !paymentStatus)}
							/>
							<CheckboxItem name={"Publish status"} isChecked={publishedStatus || false} labelText={"Mark as Published"} onChange={(e) => onChangeStateFieldHandler("publishedStatus", !publishedStatus)} />
						</Styled.CheckboxContainer>
						<Styled.Description>
							<Styled.DescriptionInput type="text" value={descriptionValue} onChange={(e) => onChangeStateFieldHandler("descriptionValue", e.target.value)} placeholder="Description" />
						</Styled.Description>
					</Styled.ReceiptStatusContainer>
				</Styled.ReceiptDetailWrapper>
			</Styled.BodyWrapper>

			<FormButtonPanel customFlexGap={"10px"}>
				<ButtonsBox onRejectButtonClickHandler={onChangeRadioButtonHandler} isLoading={isLoading} saveReceiptHandler={saveInvoiceHandler} onCancelButtonClickHandler={onCancelButtonClickHandler} onApproveButtonClickHandler={onChangeRadioButtonHandler} />
			</FormButtonPanel>
		</Styled.Section>
	);
};

{
	/* <Styled.StatusBarWrapper>
							<PhotoDetailsContentItem label="Status">
								<StatusBar status={statusValue as TStatuses} />
							</PhotoDetailsContentItem>
							<PhotoDetailsContentItem label="Recept ID">
								<StatusBar rid={receiptid} />
							</PhotoDetailsContentItem>
						</Styled.StatusBarWrapper> */
}
