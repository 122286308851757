export const STRINGS = {
  currency: 'Currency',
  dateFormat: 'Date Format',
  preferenceTitle: 'Tailor your preference ',
  buttonText: 'Continue',
  buttonTextInbox: 'Go to the application',
};


export const formikInitialValues = {
  companyName: '',
};